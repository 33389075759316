<template>
  <div>
    <DeliveryNotesProfileCard />
    <BaseDocumentationCard
      v-model="documentation"
      hide-observations
      :resource="deliveryNotes"
      :delete-end-point="deleteEndPoint"
      :update-end-point="updateEndPoint"
      :multiple="false"
      add-link-text="Adjuntar albaran"
    />
    <b-card no-body>
      <b-row
        class="p-2"
        align-v="center"
        align-h="between"
      >
        <b-col cols="auto">
          <span class="text-uppercase font-weight-bold mb-0">
            facturas asociadas
          </span>
        </b-col>
      </b-row>
      <PurchaseInvoicesTable
        :delivery-notes-id="deliveryNotesId"
        hide-toolbar
        footer-totals-visible
        disable-pagination
      />
    </b-card>
    <DeliveryNotesLinesTable />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DeliveryNotesProfileCard from '@/components/delivery-notes/card/DeliveryNotesProfileCard.vue'
import BaseDocumentationCard from '@/components/ui/card/BaseDocumentationCard.vue'
import DeliveryNotesLinesTable from '@/components/delivery-notes/table/DeliveryNotesLinesTable.vue'
import PurchaseInvoicesTable from '@/components/purchase-invoices/table/PurchaseInvoicesTable.vue'

export default {
  components: {
    PurchaseInvoicesTable, DeliveryNotesLinesTable, BaseDocumentationCard, DeliveryNotesProfileCard,
  },
  data() {
    return {
      deliveryNotesId: this.$route.params.id.toString(),
    }
  },
  computed: {
    ...mapGetters('deliveryNotes', {
      deliveryNotes: 'getDeliveryNotes',
    }),
    documentation: {
      get() {
        return this.deliveryNotes?.documentation || []
      },
      set(value) {
        this.setDeliveryNotesDocumentation(value)
      },
    },
    deleteEndPoint() {
      return this.deliveryNotes ? `/delivery-notes/delete_doc/${this.deliveryNotes.id}` : null
    },
    updateEndPoint() {
      return this.deliveryNotes ? `/delivery-notes/${this.deliveryNotes.id}` : null
    },
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    ...mapActions('deliveryNotes', ['loadDeliveryNotes', 'setDeliveryNotesDocumentation']),
    ...mapActions('app', ['setLoading']),
    async fetchData() {
      this.setLoading(true)
      try {
        await this.loadDeliveryNotes(this.$route.params.id)
      } finally {
        this.setLoading(false)
      }
    },
  },
}
</script>

<style scoped>
</style>
