<template>
  <b-card no-body>
    <b-card-title
      class="m-0 py-1 px-2"
    >
      <b-row
        align-h="end"
        align-v="center"
      >
        <b-col cols="auto">
          <b-link
            v-access="{
              resource: $data.$constants.RESOURCES.RESOURCE_DELIVERY_NOTES,
              resourceAction: $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL
            }"
            :to="{ name: 'editDeliveryNotes', params: { id: $route.params.id} }"
            class="d-inline-block px-50 text-indigo"
          >
            <feather-icon
              v-b-tooltip.hover 
              title="Editar"
              icon="Edit2Icon"
              size="18"
            />
          </b-link>
          <b-link
            v-access="{
              resource: $data.$constants.RESOURCES.RESOURCE_DELIVERY_NOTES,
              resourceAction: $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL
            }"
          >
            <span
              class="d-inline-block px-50 text-danger"
              @click="handleDeleteButtonClick()"
            >
              <feather-icon
                v-b-tooltip.hover 
                title="Eliminar"
                icon="TrashIcon"
                size="18"
              />
            </span>
          </b-link>
        </b-col>
      </b-row>
      <b-row
        align-v="center"
        align-h="between"
      >
        <b-col cols="auto">
          <small class="text-dark font-weight-bold">Albarán Nº {{ deliveryNotes ? deliveryNotes.number : '' }}</small>
          <div class="font-weight-bold text-capitalize text-dark mb-0">
            {{ deliveryNotes && deliveryNotes.provider ? deliveryNotes.provider.name : '' }}
            <StatusSelect
              v-model="status"
              class="font-small-3 d-inline-block"
              :type="statusType"
              :clearable="false"
              hide-borders
              @selection-change="handleStatusSelectionChange"
            />
          </div>
          <small class="font-weight-bold">{{ deliveryNotesDate }}</small>
        </b-col>
      </b-row>
    </b-card-title>
  </b-card>
</template>

<script>
import { DELIVERY_NOTES_STATUS_TYPE } from '@/api/status-api'
import { mapActions, mapGetters } from 'vuex'
import ApiRestService from '@/api/base-api'
import StatusSelect from '@/components/status/select/StatusSelect.vue'

export default {
  name: 'DeliveryNotesProfileCard',
  components: { StatusSelect },
  data() {
    return {
      statusType: DELIVERY_NOTES_STATUS_TYPE,
    }
  },
  computed: {
    ...mapGetters('deliveryNotes', {
      deliveryNotes: 'getDeliveryNotes',
    }),
    deliveryNotesDate() {
      return this.deliveryNotes?.delivery_note_date ? new Date(this.deliveryNotes.delivery_note_date).toLocaleDateString() : ''
    },
    status: {
      get() {
        return this.deliveryNotes?.status
      },
      set(value) {
        this.deliveryNotes.status = value
      },
    },
  },
  methods: {
    ...mapActions('app', ['setLoading']),
    async handleDeleteButtonClick() {
      const response = await this.$modal.show({
        title: '¿Está seguro?',
        text: `Se borrará el registro Nº${this.deliveryNotes.number}.`,
      })

      if (!response.isConfirmed) {
        return
      }

      this.setLoading(true)
      try {
        await ApiRestService.delete(this.deliveryNotes?.id, this.$data.$constants.RESOURCES.RESOURCE_DELIVERY_NOTES)
        await this.$router.push({ name: 'deliveryNotes' })
      } finally {
        this.setLoading(false)
      }
    },
    handleStatusSelectionChange() {
      ApiRestService.edit(this.deliveryNotes?.id, this.deliveryNotes, this.$data.$constants.RESOURCES.RESOURCE_DELIVERY_NOTES)
    },
  },
}
</script>

<style scoped>
</style>
